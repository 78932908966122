//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import i18n from '@/vueI18n';

//#region [ Zebra Printer ]
import * as BrowserPrint from '../BrowserPrint-3.0.216.min.js'
var selected_device;
var devices = [];
function setup() {
	//Get the default device from the application as a first step. Discovery takes longer to complete.
	BrowserPrint.getDefaultDevice("printer", function(device) {
		//Add device to list of devices and to html select element
		selected_device = device;
		devices.push(device);
		var html_select = document.getElementById("selected_device");
		var option = document.createElement("option");
		option.text = device.name;
		html_select.add(option);
		
		//Discover any other devices available to the application
		BrowserPrint.getLocalDevices(function(device_list){
			for(var i = 0; i < device_list.length; i++)
			{
				//Add device to list of devices and to html select element
				var device = device_list[i];
				if(!selected_device || device.uid != selected_device.uid)
				{
					devices.push(device);
					var option = document.createElement("option");
					option.text = device.name;
					option.value = device.uid;
					html_select.add(option);
				}
			}
			
		}, function(){alert("Error getting local devices")},"printer");
		
	}, function(error){
		alert(error);
	})
}
// function getConfig(){
// 	BrowserPrint.getApplicationConfiguration(function(config){
// 		alert(JSON.stringify(config))
// 	}, function(error){
// 		alert(JSON.stringify(new BrowserPrint.ApplicationConfiguration()));
// 	})
// }
function writeToSelectedPrinter(dataToWrite) {
  selected_device.send(dataToWrite, undefined, errorCallback);
}
var errorCallback = function(errorMessage) {
	alert("Error: " + errorMessage);	
}
// var readCallback = function(readData) {
// 	if(readData === undefined || readData === null || readData === "") {
// 		alert("No Response from Device");
// 	}	else {
// 		alert(readData);
// 	}
// }
// function readFromSelectedPrinter() {
// 	selected_device.read(readCallback, errorCallback);
// }
// function getDeviceCallback(deviceList) {
// 	alert("Devices: \n" + JSON.stringify(deviceList, null, 4))
// }
// function sendImage(imageUrl) {
// 	var url = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
// 	url = url + "/" + imageUrl;
// 	selected_device.convertAndSendFile(url, undefined, errorCallback)
// }
// function sendFile(fileUrl) {
//     var url = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
//     url = url + "/" + fileUrl;
//     selected_device.sendFile(url, undefined, errorCallback)
// }
// function onDeviceSelected(selected) {
// 	for(var i = 0; i < devices.length; ++i){
// 		if(selected.value == devices[i].uid) {
// 			selected_device = devices[i];
// 			return;
// 		}
// 	}
// }
// window.onload = setup;
//#endregion

export default {
  name: 'app-print-barcode-modal',
  props: ['visible', 'serialNumber'],

  mounted() {
    setup()  
  },
  data() {
    return {
      printingLoading: false,
      printCount: undefined,
    };
  },
  computed: {
    ...mapGetters({
      settings: 'settings/settings',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    language(){
      return i18n.locale
    },
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    doClose() {
      this.$emit('close')
    },
    printBarcode() {
      this.printingLoading = true
      
      setup()    
      const printingCount = parseInt(this.printCount)

      // const title = 'Puricom Egypt'
      const barcodeTitle = this.settings ? this.settings.barcodeTitle : ''
      const barcode = `^XA^FO80,40^CFD^FD${barcodeTitle}^FS^FO40,70^BY2^BCN,100,Y,N,N^FD${this.serialNumber}^FS^XZ`

      for (let i = 0; i < printingCount; i++) {
        writeToSelectedPrinter(barcode)
      }

      setTimeout(() => {
        this.printingLoading = false
        this.doClose()
      }, 1000);
    },

  },
};
